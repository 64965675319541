export const RechargeApi = {
  list: '/api/v2/store/recharge/denomination/list',
  create: '/api/v2/store/recharge/order/create'
}


export default (request) => {
  return {
    getRechargeList: (params) => request.get(RechargeApi.list, { params }),
    createRechargeOrder: (params, headers) => request.post(RechargeApi.create, params, { headers }),
  }
}
