export const InviteType = {
  count: 13,
  payment: 12,
  login: 21
}

export const VipStatusTypeNameMap = {
  'PAYMENT': 'siteBuild.payment',
  'REDEEM': 'siteBuild.redeem',
  'UPGRADE': 'siteBuild.upgrade',
  'REFUND': 'siteBuild.refund'
}

export const BusEventMap = {
  Payment: 'payment',
  LoginOut: 'login-out',
  Login: 'login',
}


export const TypeData = {
  recharge: 'recharge'
}
